import { StyledButton } from "./styles";
import { ButtonProps } from "../types";

const Button = (props: ButtonProps) => (
  <StyledButton color={props.color} fixedWidth={props.fixedWidth} onClick={props.onClick}>
    {props.children}
  </StyledButton>
);

export { Button };

