import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import i18n from "i18next";
import {
  FooterSection,
  Para,
  Large,
  Chat,
  FooterContainer,
  Language,
  Divider,
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: any) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
        style={{ margin: "0 20px 1em 20px" }}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between" align="bottom" >
            <Col lg={10} md={10} sm={12} xs={12}>
              <Language>{t("Contact")}</Language>
              <Para>
                {t(`Do you have any questions? Feel free to reach out!`)}
              </Para>
              <a href="mailto:hello@otterlygood.com">
                <Chat>{t(`Let's Chat`)}</Chat>
              </a>
            </Col>


          </Row>

          <br></br>

          <Row style={{ justifyContent: "space-between" }}>
            <Row>
              <Row>
                <Para style={{ marginRight: "50px" }} >Manchester</Para>
              </Row>
              <Row>
                <Para style={{ marginRight: "50px" }} >United Kingdom</Para>
              </Row>
              <Row>
                <a href="mailto:hello@otterlygood.com">
                  <Para style={{ marginRight: "50px" }} >hello@otterlygood.com</Para>
                </a>
              </Row>
              <Row style={{ justifyContent: "center", whiteSpace: "nowrap" }}>
                <Para style={{ textAlign: "center" }}>© 2023 Otterly Good Ltd</Para>
              </Row>
            </Row>

            {/* TODO: update these links */}
            {/* TODO: enable again in final version of site */}
            {/* <Row>
              <FooterContainer>
                <SocialLink
                  href="https://github.com/Adrinlol/create-react-app-adrinlol"
                  src="github.svg"
                />
                <SocialLink
                  href="https://twitter.com/Adrinlolx"
                  src="twitter.svg"
                />
                <SocialLink
                  href="https://www.linkedin.com/in/lasha-kakabadze/"
                  src="linkedin.svg"
                />
                <SocialLink
                  href="https://medium.com/@lashakakabadze/"
                  src="medium.svg"
                />
              </FooterContainer>
            </Row> */}

            {/* <Row>
              <Large to="/terms" left="true" style={{ marginRight: "50px" }} >
                {t("Terms and Conditions")}
              </Large>
              <Large left="true" to="/privacy" style={{ marginRight: "10px" }}>
                {t("Privacy Policy")}
              </Large>
            </Row> */}

          </Row>

          {/* <Divider></Divider> */}

          {/* <Row style={{ justifyContent: "center", marginBottom: "-1.5rem" }}>
            <Para style={{ textAlign: "center" }}>© 2023 Otterly Good Ltd</Para>
          </Row> */}
        </Container>
      </FooterSection>
    </>
  );
};

export default withTranslation()(Footer);
