const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/viajo/terms"],
    exact: true,
    component: "ViajoTerms",
  },
  {
    path: ["/viajo/privacy"],
    exact: true,
    component: "ViajoPrivacy",
  },
  {
    path: ["/viajo/delete-account"],
    exact: true,
    component: "ViajoDeleteAccount",
  },
];

export default routes;
